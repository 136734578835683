import { Component, OnInit,ViewChild, ElementRef } from '@angular/core';
import * as JsBarcode from 'jsbarcode';
import { ApiService } from 'src/app/service/api.service';


@Component({
  selector: 'barcode',
  template: '<canvas #barcodeCanvas></canvas>'
})
export class BarcodeComponent implements OnInit {
  customerNamePresent: any = false
  barcodeValue: string;
  @ViewChild('barcodeCanvas') canvas: ElementRef;

  constructor(private apiService: ApiService) {
    
   }

  ngAfterViewInit(): void {
    const canvas = this.canvas.nativeElement;
  JsBarcode(canvas, this.barcodeValue, {
    // options go here
  });
  }

  ngOnInit(): void {
    let rewardDetailwithBarcode = JSON.parse(sessionStorage.getItem('rewardDetailwithBarcode'));
    this.barcodeValue = rewardDetailwithBarcode.code
    
  }

}
