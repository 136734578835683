<section class="globalContainer">
  <div class="dashboard-container profileBG ensure-dashboard-container">
    <div class="dasboard-profile-card pb-4">
      <div class="brand-selector-icon">
        <img
          (click)="openHamberger()"
          src="./../../../assets/images/ensure/brand_selector_icon.png"
          alt="img"
        />
      </div>
      <div class="row mx-0 px30 profile-details">
        <div class="col-6 point-text pl-0">
          <p class="">Hello</p>
          <p class="profile-name" *ngIf="customerNamePresent">
            {{ customerName }}
          </p>
        </div>
        <div class="col-6 text-right pr-0">
          <p class="point-text" *ngIf="customerNamePresent">
            Total Reward Points
          </p>
          <p class="points" *ngIf="customerNamePresent">{{ loyaltyPoints | number }}</p>

          <!-- <p class="points" *ngIf="customerNamePresent">{{ loyaltyPoints }}</p> -->
          <a
            *ngIf="!customerNamePresent"
            class="color-white"
            (click)="onClickAccoutLogin()"
            >Create account/login</a
          >
        </div>
      </div>
    </div>
    <div class="dashboard-page bg-white btl30 btr30">
      <img
        src="../../../assets/images/ensure/ensure_logo.svg"
        alt="Pediasure"
      />
      <p class="mt-4 fs20">Learn more about Ensure<sup>®</sup></p>
      <div
        class="dashboard-card row mx-0"
        [routerLink]="['/ensure/about-ensure-life']"
      >
        <div class="col-2">
          <img
            src="../../../assets/images/ensure/about_ensure_life.png"
            alt="About Ensure"
          />
        </div>
        <div class="col-10">
          <p>About Ensure<sup>®</sup> Life</p>
        </div>
      </div>
      <div
        class="dashboard-card row mx-0"
        [routerLink]="['/ensure/what-is-hmb']"
      >
        <div class="col-2">
          <img src="../../../assets/images/ensure/HMB.svg" alt="Heart" />
        </div>
        <div class="col-10">
          <p>What is HMB?</p>
        </div>
      </div>
      <div
        class="dashboard-card row mx-0"
        [routerLink]="['/ensure/muscle-health']"
      >
        <div class="col-2">
          <img
            src="../../../assets/images/ensure/muscle_strength.svg"
            alt="muscle strength"
          />
        </div>
        <div class="col-10">
          <p>How to build and preserve muscle strength</p>
        </div>
      </div>
      <div class="mt30">
        <p class="color-lightgrey fs12">SG.2022.27647.ENS.1</p>
      </div>
    </div>
  </div>
</section>
<!-- <app-bottom-menu></app-bottom-menu> -->
<app-menu [customerNamePresent]="customerNamePresent"></app-menu>
<div
  bsModal
  #hambergermenu="bs-modal"
  class="modal fade customModalContainer"
  tabindex="-1"
  role="dialog"
  aria-labelledby="dialog-child-name"
>
  <div class="modal-dialog modal-sm mx-auto">
    <div class="modal-content">
      <div class="modal-body text-center">
        <div class="modal-blue-bg">
          <div class="customModal-content">
            <div class="d-flex top-bar">
              <div class="text-left">
                <img
                  (click)="closeHamberger()"
                  src="./../../../assets/images/ensure/close-hamberger.svg"
                />
              </div>
              <div class="text-right">
                <img
                  src="./../../../assets/images/ensure/abbott-family-reward.svg"
                />
              </div>
            </div>
            <div class="px20 pt-5">
              <h3 class="text-left mb-3">SELECT BRAND</h3>
              <div class="d-flex brands-list">
                <div>
                  <img
                    [routerLink]="['/dashboard']"
                    (click)="selectBrand('pediasure')"
                    src="./../../../assets/images/ensure/brand_pediasure_logo.png"
                  />
                </div>
                <div>
                  <img
                    (click)="ensureClick()"
                    src="./../../../assets/images/ensure/brand_ensure_logo.png"
                  />
                </div>
              </div>
              <div class="d-flex brands-list">
                <div>
                  <img
                    class="glucerna-img"
                    src="./../../../assets/images/glucerna/coming-soon-new.png"
                  />
                  <strong class="coming-july">COMING SOON ON 10TH AUGUST</strong>
                </div>
                
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
