import { Injectable } from '@angular/core';
import Bugsnag from '@bugsnag/js';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class BugsnagService {

  constructor() { }
  
  notifyError(error, payload?){
    // if(!environment.production){
    //   return;
    // }
    Bugsnag.notify(JSON.stringify(error),function (event) {
      if (event.getUser().id === '1') return false
      event.severity = 'info'
      let user = getMyUserIdentifier()
      console.log("user =>", user);
      event.context = event.context
      event.setUser(user)
    })
    if(payload){
        Bugsnag.notify(JSON.stringify(payload),function (event) {
          if (event.getUser().id === '1') return false
          event.severity = 'info'
          let user = getMyUserIdentifier()
          event.context = event.context
          event.setUser(user)
        })
    }
  }
}
function getMyUserIdentifier(){
  let getMobile = sessionStorage.getItem('mobileNo');
  return getMobile
}