
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { ApiService } from '../../app/service/api.service';
import { first } from 'rxjs/operators';
import { BugsnagService } from '../services/bugsnag.service';
import * as ScanditSDK from "scandit-sdk";
import { BarcodePicker, ScanResult, configure } from "scandit-sdk";
import { environment as env } from '../../environments/environment';

//import jsQR from 'jsqr';
declare var ZXing:any;

@Component({
  selector: 'app-qr-code-scanner',
  templateUrl: './qr-code-scanner.component.html',
  styleUrls: ['./qr-code-scanner.component.scss']
})
export class QrCodeScannerComponent implements OnInit {
  user: any;
  userPoints: any;
  showProfile: Boolean = true;
  showScanner: boolean = true;
  loader: boolean = true;
  public scannerReady = false;
  public showButton = false;
  public showDescription = true;
  public result = "";
  iSprintData: any;
  image: string;
  customerName: any;
  suspiciousCount = 0;
  prodImage: any;

  categoryCode: any;
  scancodeData: any;
  scanCode: any;
  pointsEarned: any;
  videoStart = false;
  tabId = 'ensure-tab';
  latitude: string;
  longitude: string;
  confidence: string;
  medias: MediaStreamConstraints = {
    audio: false,
    video: false,
  };
  @ViewChild("barcodePicker") barcodePickerElement: ElementRef<HTMLDivElement & { barcodePicker: BarcodePicker }>;
  @ViewChild('video', { static: true }) videoElm: ElementRef;
  @ViewChild('canvas', { static: true }) canvasElm: ElementRef;
  @ViewChild('successScanModal', { static: true })
  successScanModal: ModalDirective;
  @ViewChild('failedScanModal', { static: true })
  failedScanModal: ModalDirective;
  @ViewChild('camerapermission', { static: true }) camerapermission: ModalDirective;
  scannedCode: string;

  constructor(
    private apiService: ApiService,
    private router: Router,
    private spinner: NgxSpinnerService,
    private bugsnagService: BugsnagService
  ) {
    this.getPosition();
    configure(env.LICENSEKEY, {
      engineLocation: "https://cdn.jsdelivr.net/npm/scandit-sdk@5.x/build/",
    });
  }

  ngOnInit(): void {
    this.getCustomer();
    this.iSprintData = sessionStorage.getItem('iSprintData');
    this.iSprintData = JSON.parse(this.iSprintData);
    console.log(this.iSprintData);

    this.showProfile = false;


  }

  ontabChange(ids: any) {
    this.tabId = ids;
  }

  // this.showScanner = true;

  // toggleVideoMedia() {
  //   if (this.videoStart) {
  //     this.stopVideo();
  //   } else {
  //     this.startVideo();
  //   }
  // }
  // startVideo() {
  //   this.medias.video = { facingMode: 'environment', width: 100, height: 150 };
  //   navigator.mediaDevices
  //     .getUserMedia(this.medias)
  //     .then((localStream: MediaStream) => {
  //       this.videoElm.nativeElement.srcObject = localStream;
  //       this.videoStart = true;
  //       this.checkImage();
  //     })
  //     .catch((error) => {
  //       console.error(error);
  //       this.videoStart = false;
  //     });
  // }
  // stopVideo() {
  //   this.medias.video = false;
  //   this.videoElm.nativeElement.srcObject.getVideoTracks()[0].enabled = false;
  //   this.videoElm.nativeElement.srcObject.getVideoTracks()[0].stop();
  //   this.videoStart = false;
  // }
  // checkImage() {
  //   const WIDTH = this.videoElm.nativeElement.clientWidth;
  //   const HEIGHT = this.videoElm.nativeElement.clientHeight;
  //   this.canvasElm.nativeElement.width = WIDTH;
  //   this.canvasElm.nativeElement.height = HEIGHT;

  //   const ctx = this.canvasElm.nativeElement.getContext(
  //     '2d'
  //   ) as CanvasRenderingContext2D;

  //   ctx.drawImage(this.videoElm.nativeElement, 0, 0, WIDTH, HEIGHT);
  //   const imageData = ctx.getImageData(0, 0, WIDTH, HEIGHT);
  //   const code = jsQR(imageData.data, imageData.width, imageData.height, {
  //     inversionAttempts: 'dontInvert',
  //   });
  //   if (code) {
  //     let data = code.data;
  //     console.log(data, 'scanned data===');
  //     let array = data.split('=');
  //     let scannedCode = array[1];
  //     this.scanCode = scannedCode;
  //     console.log(scannedCode, ' scannedCode');
  //     sessionStorage.setItem('scannedCode', scannedCode);
  //     this.scannedCode = sessionStorage.getItem('scannedCode');
  //     console.log(this.scannedCode, 'scanCode first');
  //     this.spinner.show();
  //     this.apiService
  //       .productinfo(
  //         scannedCode,
  //         this.latitude,
  //         this.longitude,
  //         this.confidence
  //       )
  //       .subscribe((res: any) => {
  //         console.log(res, 'res isprint');
  //         this.spinner.hide();
  //         if (res.status === 'success') {
  //           sessionStorage.setItem('iSprintData', JSON.stringify(res.result));
  //           // this.closeScanQrcodeGuideModal();
  //           // this.onClickCancelModalone();
  //           this.router.navigate(['/disclaimer']);
  //         } else {
  //           alert('Please scan a valid QR code.');
  //         }
  //       }, (err) => {
  //         this.spinner.hide()
  //         if (err?.error?.code == 400) {
  //           alert(err?.error?.message)
  //         }
  //         else if (err?.error?.code == 420) {
  //           alert(err?.error?.message)
  //         } else {
  //           console.log(err.error.message);
  //         }
  //         this.router.navigate(['/disclaimer']);
  //         alert(err.error.message);
  //         this.loader = false;
  //       });
  //   } else {
  //     setTimeout(() => {
  //       this.checkImage();
  //     }, 100);
  //   }

  // }

  public onReady(): void {
    console.log("onReady")
    console.log("this.scannerReady", this.scannerReady)
    console.log("this.showButton", this.showButton)
    // this.scannerReady = true;
    // this.showButton = true;
    this.barcodePickerElement.nativeElement.barcodePicker.setVisible(true).resumeScanning();
  }
  onScanError(error) {
    console.log("error=>", error);
    this.bugsnagService.notifyError(error)
    this.apiService.showToaster("Sorry! Something Went Wrong")
  }
  public onScan(scanResult: { detail: ScanResult }): void {
    const calculatedString = scanResult.detail.barcodes.reduce((result, barcode) => {
      console.log("scanResult =>", scanResult);
      return ` ${barcode.data}`;
    }, "");
    this.result = calculatedString;
    if (this.result) {
      this.onClickScanner(this.result)
    }
  }

  onClickScanner(result:any) {
    this.showScanner = true;
    //this.showData = false;
    const barcodePickerElements = document.getElementById("barcodePicker");
    if (result) {
      console.log(result)
      //document.getElementById('result').textContent = result.text

      //if (code) {
        let data = result;
        console.log(data, 'scanned data===')
        let array = data.split("=");
        let scannedCode = array[1];
        this.scanCode = scannedCode;
        console.log(scannedCode, ' scannedCode')
        sessionStorage.setItem('scannedCode', scannedCode);
        let scanCode = sessionStorage.getItem('scannedCode');
        console.log(scanCode, 'scanCode first');
        this.spinner.show()
        this.apiService.productinfo(scannedCode, this.latitude, this.longitude, this.confidence).subscribe((res: any) => {
          console.log(res, 'res isprint');
  
          let weight: any = res?.result?.size
          let weightinGrs = weight?.includes('GRS');
          var kilograms
          if (weightinGrs) {
            weight = weight.replace("GRS", "");
            kilograms = parseInt(weight) / 1000;
            console.log(kilograms + " Kilograms");
  
          }
          sessionStorage.setItem('productWeight', kilograms)
  
          this.spinner.hide()
          if (res.status === 'success') {
            sessionStorage.setItem('iSprintData', JSON.stringify(res.result));
            //this.closeScanQrcodeGuideModal();
            this.router.navigate(['/disclaimer']);
          } else {
            alert('Please scan a valid QR code.');
          }
        },err=>{
          this.spinner.hide();
          console.log(err, 'err ---');
          alert(err.error.message)
          let payload = {
            scannedCode,
            latitude: this.latitude, 
            longitude: this.longitude, 
            confidence: this.confidence
          }
          this.bugsnagService.notifyError(err, payload);
        })
  
      //}



    }
    // let selectedDeviceId;
    // const codeReader = new ZXing.BrowserMultiFormatReader()
    // console.log('ZXing code reader initialized')
    // codeReader.listVideoInputDevices()
    //   .then((videoInputDevices) => {
    //     const sourceSelect = document.getElementById('sourceSelect')
    //     selectedDeviceId = videoInputDevices[0].deviceId
    //       codeReader.decodeFromVideoDevice(undefined, 'video', (result, err) => {
          
    //         if (err && !(err instanceof ZXing.NotFoundException)) {
    //           console.error(err)
    //           document.getElementById('result').textContent = err
    //         }
    //       })
    //       console.log(`Started continous decode from camera with id ${selectedDeviceId}`)
    //   })
    //   .catch((err) => {
    //     console.error(err)
    //   })


    // this.showData = false;

    // if (this.showScanner) {
    //   this.startVideo();
    // }
  }
  closeScanner() {
    this.showScanner = false;
  }


  getCustomer() {
    let mobile = sessionStorage.getItem('mobileNo');
    let token = sessionStorage.getItem('authToken');
    this.apiService.getCapCustomer(mobile, token).subscribe((res) => {
      console.log(res, 'res---');
      console.log(res['customers']['customer'][0].user_id, 'used id---');
      sessionStorage.setItem(
        'abbottUserId',
        res['customers']['customer'][0].user_id
      );
      this.user = res['customers']['customer'][0];
      this.userPoints = res['customers']['customer'][0]?.loyalty_points
        ? res['customers']['customer'][0]?.loyalty_points
        : '0';
      console.log(this.userPoints, 'this.userPoints');
      let lifetimepoints = res['customers']['customer'][0].lifetime_purchases;
      console.log(this.userPoints, 'this.userPoints');
      console.log(lifetimepoints, 'lifetimepoints');
      this.customerName = this.user.firstname + ' ' + this.user.lastname;
      console.log(this.customerName);
      this.user.custom_fields.field.forEach((element) => {
        if (element.name === 'suspicious_count') {
          this.suspiciousCount = parseInt(element.value);
        }
      });
    }, err => {
      console.log(err);
      this.spinner.hide()
      this.bugsnagService.notifyError(err);
    });
  }

  getPosition() {
    this.apiService.getPosition().then((pos) => {
      let lat = pos.lat;
      let lang = pos.lng;
      let confidence = pos.accuracy;
      this.latitude = JSON.stringify(lat);
      this.longitude = JSON.stringify(lang);
      this.confidence = JSON.stringify(confidence);
      if (this.showScanner) {
        this.onClickScanner(this.result);
      }
      if (this.loader && this.scanCode) {
        this.getTraceabilityContent();
      }
    }, err =>{
      this.bugsnagService.notifyError(err);
    });
  }

  getTraceabilityContent() {
    this.loader = true;
    this.apiService
      .productinfo(
        this.scanCode,
        this.latitude,
        this.longitude,
        this.confidence
      )
      .subscribe(
        (res: any) => {
          this.loader = false;
          console.log(res, 'res isprint');

          if (res.status === 'success') {
            sessionStorage.setItem('iSprintData', JSON.stringify(res.result));
            this.router.navigate(['/disclaimer']);

            // this.router.navigate(['/home']);
          } else {
            alert('Please scan a valid QR code.');
          }
        },
        (err) => {

          if (err?.error?.code == 400) {
            alert(err?.error?.message)
          }
          else if (err?.error?.code == 420) {
            alert(err?.error?.message)
          } else {
            console.log(err.error.message);
          }
          this.router.navigate(['/disclaimer']);
          alert(err.error.message);
          this.loader = false;
          let payload = {
            scanCode: this.scanCode,
            latitude: this.latitude,
            longitude: this.longitude,
            confidence: this.confidence
          }
          this.bugsnagService.notifyError(err, payload);
        }
      );
  }

  parseFunction(value) {
    return JSON.parse(value);
  }

}


