<section class="globalContainer">
  <div class="dashboard-container profileBG">
    <div class="dasboard-profile-card pb-4">
      <div class="brand-selector-icon">
        <img
          (click)="openHamberger()"
          src="./../../../assets/images/ensure/brand_selector_icon.png"
          alt="img"
        />
      </div>
      <div class="row mx-0 px30 profile-details">
        <div class="col-6 point-text pl-0">
          <p class="">Hello</p>
          <p class="profile-name" *ngIf="customerNamePresent">
            {{ customerName }}
          </p>
        </div>
        <div class="col-6 text-right pr-0">
          <p class="point-text" *ngIf="customerNamePresent">
            Total Reward Points
          </p>
          <!-- <p class="points" *ngIf="customerNamePresent">{{ loyaltyPoints }}</p> -->
          <p class="points" *ngIf="customerNamePresent">{{ loyaltyPoints | number }}</p>

          <a
            *ngIf="!customerNamePresent"
            class="color-white"
            (click)="onClickAccoutLogin()"
            >Create account/login</a
          >
        </div>
      </div>
    </div>
    <div class="dashboard-page bg-white btl30 btr30">
      <img src="../../../assets/images/pediasure.png" alt="Pediasure" />
      <p class="mt-4 fs20">Learn more about PediaSure<sup>®</sup></p>
      <div class="dashboard-card row mx-0" [routerLink]="['/products']">
        <div class="col-2">
          <img src="../../../assets/images/star.png" alt="Star" />
        </div>
        <div class="col-10">
          <p>Check out PediaSure<sup>®</sup> products</p>
        </div>
      </div>
      <div
        class="dashboard-card row mx-0"
        [routerLink]="['/product-testimonial']"
      >
        <div class="col-2">
          <img src="../../../assets/images/heart.png" alt="Heart" />
        </div>
        <div class="col-10">
          <p>Read testimonials</p>
        </div>
      </div>
      <div class="dashboard-card row mx-0" [routerLink]="['/buy-local']">
        <div class="col-2">
          <img src="../../../assets/images/buy-local.png" alt="Local" />
        </div>
        <div class="col-10">
          <p>Buy Local</p>
          <span>SG Trust Communication</span>
        </div>
      </div>
      <div class="mt30">
        <p class="color-lightgrey fs12">SG.2021.21793.ALL.1 (v1.0)</p>
      </div>
    </div>
  </div>
</section>
<!-- <app-bottom-menu></app-bottom-menu> -->
<app-menu [customerNamePresent]="customerNamePresent"></app-menu>
<div
  bsModal
  #hambergermenu="bs-modal"
  class="modal fade customModalContainer"
  tabindex="-1"
  role="dialog"
  aria-labelledby="dialog-child-name"
>
  <div class="modal-dialog modal-sm mx-auto">
    <div class="modal-content">
      <div class="modal-body text-center">
        <div class="modal-blue-bg">
          <div class="customModal-content">
            <div class="d-flex top-bar">
              <div class="text-left">
                <img
                  (click)="closeHamberger()"
                  src="./../../../assets/images/ensure/close-hamberger.svg"
                />
              </div>
              <div class="text-right">
                <img
                  src="./../../../assets/images/ensure/abbott-family-reward.svg"
                />
              </div>
            </div>
            <div class="px20 pt-5">
              <h3 class="text-left mb-3">SELECT BRAND</h3>
              <div class="d-flex brands-list">
                <div>
                  <img
                    (click)="pediasureClick()"
                    src="./../../../assets/images/ensure/brand_pediasure_logo.png"
                  />
                </div>
                <div>
                  <img
                    [routerLink]="['/ensure/dashboard']"
                    (click)="selectBrand('ensure')"
                    src="./../../../assets/images/ensure/brand_ensure_logo.png"
                  />
                </div>
              </div>
              <div class="d-flex brands-list">
                <div>
                  <img
                    class="glucerna-img"
                    src="./../../../assets/images/glucerna/coming-soon-new.png"
                  />
                  <strong class="coming-july">COMING SOON ON 10TH AUGUST</strong>
                </div>
                
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
