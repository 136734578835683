<app-header title="QR Scanner"></app-header>

<div [hidden]="!showScanner">
  <section id="demo-content">
    <scandit-barcode-picker
      #barcodePicker
      style="border-radius: 15% !important"
      id="barcode-picker"
      scanningPaused="true"
      visible="false"
      playSoundOnScan="false"
      vibrateOnScan="true"
      scanSettings.enabledSymbologies='["QR"]'
      scanSettings.codeDuplicateFilter="-1"
      (ready)="onReady()"
      (scan)="onScan($event)"
      (scanError)="onScanError($event)"
    >
    </scandit-barcode-picker>
    <!-- <div>
      <video id="video" width="100%" height="100%"></video>
    </div> -->
    <!-- <label>Result:</label>
      <pre><code id="result"></code></pre> -->
  </section>
  <!-- <div class="video-row" fxFlex="100">
    <video class="video" #video autoplay playsinline></video>
  </div>
  <div fxFlex="100" style="display: none">
    <canvas class="video" id="canvas" #canvas></canvas>
  </div> -->
  <!-- <button class="cameraBtn" (click)="closeScanner()">back</button> -->
  <!-- <img
    class="cameraBtn"
    src="../../assets/images/Union 12.svg"
    alt="camera-button"
    (click)="closeScanner()"
  /> -->
</div>
