import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ModalDirective } from 'ngx-bootstrap/modal';
import Stepper from 'bs-stepper';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ApiService } from '../service/api.service';
import { first } from 'rxjs/operators';
import * as moment from 'moment';
import { environment as env } from 'src/environments/environment';
import { BugsnagService } from '../services/bugsnag.service';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { DecimalPipe } from '@angular/common';

@Component({
  selector: 'app-rewards-checkout',
  templateUrl: './rewards-checkout.component.html',
  styleUrls: ['./rewards-checkout.component.scss'],
  providers: [DecimalPipe],
})
export class RewardsCheckoutComponent implements OnInit {
  @ViewChild('editModal', { static: true }) editModal: ModalDirective;
  addressForm: FormGroup;
  submittedAddressForm: boolean = false;
  private stepper: Stepper;
  reviewDisabled: boolean = false;
  deliveryDisabled: boolean = true;
  confirmationDisabled: boolean = true;
  rewardInfo: any;
  count = 1;
  countInc = 1;
  disabled = false;
  IsCheckout = false;
  customerData: any;
  showAddressForm: boolean = false;
  addressData = {
    recipientName: '',
    phoneNumber: '',
    address: '',
    postalCode: '',
    unitnumber: '',
  };
  loyaltyPoints: any;
  customerNamePresent: any = false;
  intouchPoints: number;
  enufPoints: boolean = false;
  unitnumber: any;
  postalCodeVal: any;
  invalidPostalCode: boolean = false;
  invalidPostal: boolean = false;
  couponExpired: boolean = false;
  isUpdate: boolean = false;
  categorycode: string = 'ensure';
  instruction: any;
  safeHtmlContent: SafeHtml;
  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private spinner: NgxSpinnerService,
    private apiService: ApiService,
    private router: Router,
    private bugsnagService: BugsnagService,
    private sanitizer: DomSanitizer
  ) {}

  ngOnInit(): void {
    this.rewardInfo = this.route.snapshot.params;
    this.stepper = new Stepper(document.querySelector('#stepper1'), {
      linear: false,
      animation: true,
    });
    this.count = parseInt(this.rewardInfo.quantity);
    this.intouchPoints = parseInt(this.rewardInfo.intouchPoints);
    this.disabled = this.count <= 1 ? true : false;
    this.addressForm = this.formBuilder.group({
      recipientName: ['', Validators.required],
      recipientMobile: [
        '',
        [
          Validators.required,
          //Validators.pattern('^[0-9]*\.?[0-9]*$'),
          Validators.pattern(
            `^((\\+65-?)|0)?[0-9]{${env.MINMOBNUM},${env.MAXMOBNUM}}$`
          ),
        ],
      ],
      address: ['', Validators.required],
      unitnumber: ['', Validators.required],
      postalCode: ['', Validators.required],
      saveDefaultAddress: [''],
    });
    this.categorycode = sessionStorage.getItem('categoryCode');
    this.getCustomer();
    this.getInstruction()
  }

  getInstruction(){
    this.apiService.getInstruction().subscribe(
      (res:any) => {
        
        if (res['status'] == 200) {
          if(res?.data[0]?.markdownfield){
            this.instruction = res?.data[0]?.markdownfield
            this.safeHtmlContent = this.sanitizer.bypassSecurityTrustHtml(this.instruction);
          }
         
        } 
      },
      (err) => {
        console.log(err);
        this.bugsnagService.notifyError(err);
      }
    );

  }
  postalCode(event) {
    console.log(event.target.value, 'postal code');
    this.postalCodeVal = event.target.value;
  }
  backToDashboardBtn() {
    if (this.categorycode === 'ensure') {
      this.router.navigate(['/ensure/dashboard']);
    } else if (this.categorycode === 'pediasure') {
      this.router.navigate(['/dashboard']);
    } else {
      this.router.navigate(['/dashboard']);
    }
  }

  viewRewardDetailsBtn(event){
    this.router.navigate(['reward-details']);
  }
  displayAdddressForm(customerData) {
    let customAddressfields: any = [];

    customerData.custom_fields.field.forEach(({ name }) => {
      customAddressfields.push(name);
    });

    let includedAddressFields = [
      'recipientname',
      'recipientzip',
      'recipientaddress',
      'recipientphone',
      'unitnumber',
    ];

    let addressFieldNotFound = false;
    includedAddressFields.forEach((ele) => {
      if (customAddressfields.indexOf(ele) === -1) {
        addressFieldNotFound = true;
        console.log(ele);
        return;
      }
    });

    customerData.custom_fields.field.forEach((ele) => {
      if (ele.name === 'recipientname')
        this.addressData.recipientName = ele.value;
      if (ele.name === 'recipientzip') this.addressData.postalCode = ele.value;
      if (ele.name === 'recipientaddress') this.addressData.address = ele.value;
      if (ele.name === 'unitnumber') this.addressData.unitnumber = ele.value;
      if (ele.name === 'recipientphone')
        this.addressData.phoneNumber = ele.value;
    });

    this.addressForm.patchValue({
      address: this.addressData.address,
      unitnumber: this.addressData.unitnumber,
      postalCode: this.addressData.postalCode,
    });

    if (addressFieldNotFound) {
      this.showAddressForm = true;
    } else {
      // customerData.custom_fields.field.forEach((ele) => {
      //   if (ele.name === 'recipientname')
      //     this.addressData.recipientName = ele.value;
      //   if (ele.name === 'recipientzip') this.addressData.postalCode = ele.value;
      //   if (ele.name === 'recipientaddress') this.addressData.address = ele.value;
      //   if (ele.name === 'unitnumber') this.addressData.unitnumber = ele.value;
      //   if (ele.name === 'recipientphone')
      //     this.addressData.phoneNumber = ele.value;
      // });
    }
  }

  getCustomer() {
    let mobile = sessionStorage.getItem('mobileNo');
    let token = sessionStorage.getItem('authToken');
    let key = sessionStorage.getItem('authKey');
    this.spinner.show();
    this.apiService.getCapCustomer(mobile, token).subscribe(
      (res) => {
        this.spinner.hide();
        if (res['status'].code == 200) {
          this.customerData = res['customers']['customer'][0];
          this.loyaltyPoints = this.customerData.loyalty_points;
          this.displayAdddressForm(this.customerData);
          console.log(this.customerData);
          this.customerNamePresent = true;
        } else {
          this.spinner.hide();
          this.customerNamePresent = false;
        }
      },
      (err) => {
        console.log(err);
        this.spinner.hide();
        this.bugsnagService.notifyError(err);
      }
    );
  }

  next() {}

  onSubmit() {
    return false;
  }

  get addressFormFormVal() {
    return this.addressForm.controls;
  }

  onClickProceedToDelivery() {
    this.stepper.next();
    console.log(this.stepper);
    this.reviewDisabled = !this.reviewDisabled;
    this.deliveryDisabled = !this.deliveryDisabled;
  }

  onIncrement(quantity) {
    quantity = parseInt(quantity) + 1;
    this.count = quantity;
    this.disabled = this.count <= 1 ? true : false;
  }

  onDecrement(quantity) {
    quantity = parseInt(quantity) - 1;
    this.count = quantity;
    this.disabled = this.count <= 1 ? true : false;
  }

  onClickEdit() {
    this.editModal.show();
  }
  onClickClose() {
    this.editModal.hide();
    if(this.isUpdate){
     this.count = this.countInc
    }else{
      this.count = parseInt(this.rewardInfo.quantity);
    }
  }
  onClickRemove() {
    let category = this.rewardInfo.category;
    this.router.navigate(['rewards/brand', { name: category }]);
  }

  onClickUpdateQuantity(quantity) {
    this.isUpdate = true
    this.countInc = quantity;
    this.editModal.hide();
  }

  onSubmitAddressForm() {
    this.submittedAddressForm = true;

    console.log(this.stepper);
    if (this.addressForm.invalid) {
      return;
    } else {
      this.apiService.getPostalCode(this.postalCodeVal).subscribe(
        (postal: any) => {
          this.spinner.hide();
          let findPostal = postal?.data;
          console.log(findPostal, 'findPostal');
          let postalCode = postal?.data[0]?.pincode;

          findPostal.filter((pos) => {
            let finalPostal = pos?.pincode == this.postalCodeVal;
            console.log(finalPostal, 'finalPostal');

            if (finalPostal) {
              this.invalidPostalCode = true;
              console.log(this.invalidPostalCode, 'this.invalidPostalCode');
            }
          });
          if (findPostal?.length == 0) {
            this.invalidPostalCode = false;
            console.log(this.invalidPostalCode, 'this.invalidPostalCode');
          }
          if (this.invalidPostalCode == false) {
            this.invalidPostal = false;
            let addressData = this.addressForm.value;
            let filledFormValues = this.getUserForm(addressData);
            let getMobile = sessionStorage.getItem('mobileNo');
            let token = sessionStorage.getItem('authToken');
            this.spinner.show();
            this.apiService
              .updateCapCustomer(filledFormValues, getMobile, token)
              .pipe(first())
              .subscribe(
                (res) => {
                  this.spinner.hide();
                  if (res['status'].code == 200) {
                    console.log('updated');

                    this.addressData.recipientName = addressData.recipientName;
                    this.addressData.phoneNumber = addressData.recipientMobile;
                    this.addressData.address = addressData.address;
                    this.addressData.postalCode = addressData.postalCode;
                    this.addressData.unitnumber = addressData.unitnumber;
                    this.intouchPoints = parseInt(
                      this.rewardInfo.intouchPoints
                    );

                    if (this.loyaltyPoints >= this.intouchPoints) {
                      this.onClickConfirmredeemption();
                      this.enufPoints = false;
                    } else {
                      console.log('Toastr');
                      this.enufPoints = true;
                      // this.apiService.showToaster('Available points are not suffient');
                    }
                  }
                },
                (err) => {
                  this.spinner.hide();
                  console.log(err, '=== customer update error =======');
                  this.bugsnagService.notifyError(err, filledFormValues);
                }
              );
          } else if (this.invalidPostalCode == true) {
            this.invalidPostal = true;
          }
        },
        (err) => {
          this.bugsnagService.notifyError(err, {
            postalCodeVal: this.postalCodeVal,
          });
        }
      );
    }
  }

  onClickConfirmredeemption() {
    if (this.loyaltyPoints >= this.intouchPoints) {
      let time = new Date();
      this.enufPoints = false;
      let todayDate = moment(new Date()).format('YYYY-MM-DD HH:MM:SS');
      let getMobile = sessionStorage.getItem('mobileNo');
      let getAuthKey = sessionStorage.getItem('authToken');
      let formData = {
        notes: this.rewardInfo.tier,
        rewards: [
          {
            quantity: this.count,
            rewardId: this.rewardInfo.id,
          },
        ],
        redemptionTime: todayDate,
        transactionNumber: this.rewardInfo.id + '_' +this.rewardInfo.label+'_'+ time.getTime(),
      };
      this.spinner.show();
      this.apiService
        .marvalRewardIssue(formData, getMobile, getAuthKey)
        .pipe(first())
        .subscribe(
          (res: any) => {
            this.spinner.hide();
            if (res['status'].code == 200 || res['status'].success == true) {
              this.apiService.getCutomerData(formData);
              this.IsCheckout = true
              this.getCustomer();
              this.deliveryDisabled = !this.deliveryDisabled;
              this.confirmationDisabled = !this.confirmationDisabled;
              this.reviewDisabled = !this.reviewDisabled
              
              this.stepper.next();
              this.showAddressForm = false;
              let coupon = res?.rewards[0]?.intouch[0]?.couponCode
              let rewardId = res?.rewards[0]?.rewardId
              let formDatas = {
                rewards: [
                  {
                    quantity: 0,
                    rewardId:rewardId,
                    code:coupon,
                    isrewardCheckout:true
                  },
                ],
                
              };
              let rewardDetailwithBarcode = {
                   code:coupon,
              }
              this.apiService.changeBarcode(rewardDetailwithBarcode.code);
              sessionStorage.setItem('rewardDetailwithBarcode',JSON.stringify(rewardDetailwithBarcode))
              sessionStorage.setItem('formData',JSON.stringify(formDatas))
            } else if (res['status'].code != 200) {
              this.apiService.showToasterError(res['status'].message);
              console.log(res['status'].code, ' status code');
              console.log(res['status'].message, ' status message');
              this.enufPoints = true;

              if (
                res['status'].code != 200 &&
                res.rewards[0].status.code === 8004
              ) {
                console.log(
                  res.rewards[0].status.message,
                  res.rewards[0].status.code
                );
                this.couponExpired = true;
                this.enufPoints = false;
              }
            }
          },
          (err) => {
            console.log(err);
            this.spinner.hide();
            this.bugsnagService.notifyError(err, formData);
          }
        );
    } else {
      this.enufPoints = true;
    }
  }

  getUserForm(addressData) {
    return {
      root: {
        customer: [
          {
            //mobile: this.customerData.mobile,
            custom_fields: {
              field: [
                {
                  name: 'recipientname',
                  value: addressData.recipientName,
                },
                {
                  name: 'recipientphone',
                  value: addressData.recipientMobile,
                },
                {
                  name: 'recipientzip',
                  value: addressData.postalCode,
                },
                {
                  name: 'recipientaddress',
                  value: addressData.address,
                },
                {
                  name: 'unitnumber',
                  value: addressData.unitnumber,
                },
              ],
            },
          },
        ],
      },
    };
  }

  onClickEditAddress() {
    this.enufPoints = false;
    this.showAddressForm = true;
    this.addressForm.patchValue({
      recipientName: this.addressData.recipientName,
      recipientMobile: this.addressData.phoneNumber,
      address: this.addressData.address,
      postalCode: this.addressData.postalCode,
      unitnumber: this.addressData.unitnumber,
    });
  }
}
