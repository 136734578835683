<section class="globalContainer bg-lighBlue pb70">
  <div class="dashboard-container pb70">
    <div class="dasboard-profile-card pb-4 rewards-profileBg">
      <div class="transaction-backBtn" (click)="onClickBack()">
        <span>
          <i class="fa fa-angle-left"></i>
        </span>
        Back
      </div>
      <div class="row mx-0 px30">
        <div class="col-6 point-text pl-0">
          <p class="fs20 fW600 mt-3">Rewards</p>
        </div>
        <div class="col-6 text-right pr-0">
          <p class="point-text">Total Reward Points</p>
          <!-- <p class="points">{{ loyaltyPoints }}</p> -->
          <p class="points">{{ loyaltyPoints | number }}</p>
        </div>
      </div>
    </div>
    <div class="rewards-landing my-4 px30 pb-5">
      <p class="color-black fs20">Select brand of products to redeem</p>
      <div class="row">
        <!-- <img
        src="../../assets/images/details.png"
        [routerLink]="[
          '/receipt-details',
          { id: element.Id, status: element.ReceiptStatus }
        ]"
      /> -->
        <div
          class="col-6 px-2 my-3"
          [routerLink]="['/rewards/brand', { name: 'pediasure' }]"
        >
          <img
            class="w-100"
            src="../../../assets/images/brand-pediasure.png"
            alt="Brands"
          />
        </div>
        <div
          class="col-6 px-2 my-3"
          [routerLink]="['/rewards/brand', { name: 'ensure' }]"
        >
          <img
            class="w-100"
            src="../../../assets/images/brand-ensure.png"
            alt="Brands"
          />
        </div>
        <div
          class="col-6 px-2 my-3"
          [routerLink]="['/rewards/brand', { name: 'similac' }]"
        >
          <img
            class="w-100"
            src="../../../assets/images/brand-similac.png"
            alt="Brands"
          />
        </div>
        <div
          class="col-6 px-2 my-3"
          [routerLink]="['/rewards/brand', { name: 'glucerna' }]"
        >
          <img
            class="w-100"
            src="../../../assets/images/brand-glucena.png"
            alt="Brands"
          />
        </div>
      </div>
    </div>
  </div>
</section>
<app-menu [customerNamePresent]="customerNamePresent"></app-menu>
