import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { first } from 'rxjs/operators';
import { ApiService } from 'src/app/service/api.service';
import { Location } from '@angular/common';
import { BugsnagService } from 'src/app/services/bugsnag.service';
import { DecimalPipe } from '@angular/common';


@Component({
  selector: 'app-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.scss'],
  providers: [DecimalPipe],
})
export class LandingComponent implements OnInit {
  rewardsList: any;
  ensureProductList = [];
  customerData: any;
  loyaltyPoints: any;
  customerName: any;
  customerNamePresent: any;

  constructor(
    private router: Router,
    private spinner: NgxSpinnerService,
    private apiService: ApiService,
    private location: Location,
    private bugsnagService: BugsnagService
  ) {}

  ngOnInit(): void {
    this.getCustomer();
  }
  onClickBack() {
    this.location.back();
  }

  getCustomer() {
    let mobile = sessionStorage.getItem('mobileNo');
    let token = sessionStorage.getItem('authToken');
    let key = sessionStorage.getItem('authKey');
    this.spinner.show();
    this.apiService.getCapCustomer(mobile, token).subscribe(
      (res) => {
        if (res['status'].code == 200) {
          this.spinner.hide();
          this.customerData = res['customers']['customer'][0];
          this.loyaltyPoints = this.customerData.loyalty_points;
        }
        //set customerNamePresent
        if (res['status'].code == 200) {
          this.customerName =
            this.customerData.firstname + ' ' + this.customerData.lastname;
          console.log(this.customerName);
          this.customerNamePresent = true;
        } else {
          this.spinner.hide();
          this.customerNamePresent = false;
        }
      },
      (err) => {
        console.log(err);
        this.spinner.hide();
        this.bugsnagService.notifyError(err);
      }
    );
  }
}
