<div class="pageHeader row mx-0 px30 ">
    <div class="col-12 justify-content-center d-flex position-relative header-height align-items-center d-flex">
        <a  (click)="onClickBack()">
                <i class="fa fa-angle-left"></i>
        </a>
        <h1>Reward details</h1>
    </div>
</div>

<section  class="rewardDetailsContainer pb70">
    <div  class="primary-container px30 pt-4 reward-bg">
    </div>
       <div class="circle">
        <div *ngIf="isImageDisplay; else elseBlock">
          <img [src]="rewardDetails.image"  class="image-circle" alt="img" />
          </div>
          
          <ng-template #elseBlock>
            <img  src="../../../../assets/images/gift-blue.png" alt="img" />
          </ng-template>
          <!-- <figure>
          <img  *ngIf="rewardDetails?.image != 'null'; else elseBlock" [src]="rewardDetails.image"  alt="img" />
          </figure>

          <figure>
            <img  src="../../../../assets/images/gift-blue.png" alt="img" />
          </figure> -->

        </div>
      <div>
        <!-- <p>{{rewardDetailwithBarcode?.name}}</p> -->
        <h4 class="prd-name">{{rewardDetails?.name}} </h4>
        <div class="margin-10">
         <div class="text-container">
            <p class="status-left">Status</p>
            <p class="status-right">Active</p>
          </div>
          <div class="text-container">
            <p class="date-left">Expiration date</p>
            <p class="date-right">{{rewardDetails?.expiry}}</p>
          </div>
         </div>

          <div class="text-container" *ngIf="loader">
            <p class="gray-color">Barcode</p>
            <barcode></barcode>
          </div>
        <div class="term-cond">
          <p class="gray-color">
            Terms and Conditions
          </p>
          <!-- <div [innerHTML]="externalHtml"></div> -->
          <!-- <p>  {{ rewardDetails?.termNConditionsUrl }}</p> -->
          <p class="t-text" [innerHTML]="safeHtmlContent"></p>

          <!-- <p  class="t-text">  {{termsAndCondition }}</p> -->
        </div>
     </div>
       <!-- <div class="go-back-btn">
        <button class="btn btn-primary" (click)="backtoList()">Go back</button>
       </div> -->
</section>
<app-menu></app-menu>
<!-- <p>reward-details works!</p> -->
