import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../service/api.service';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
@Component({
  selector: 'app-reward-details',
  templateUrl: './reward-details.component.html',
  styleUrls: ['./reward-details.component.scss']
})
export class RewardDetailsComponent implements OnInit {
  formData: any;
  voucherList:any;
  rewardDetails:any;
  findRewardDetails:any;
  loader:Boolean =false
  isImageDisplay:Boolean =false;
  termsAndCondition:any;
  termAndCon: any;
  safeHtmlContent: SafeHtml;
  constructor(private apiService: ApiService, private route: ActivatedRoute,private router: Router, private sanitizer: DomSanitizer) { }

  async ngOnInit() {
    let mobile = sessionStorage.getItem('mobileNo');
    let token = sessionStorage.getItem('authToken');
    // this.apiService.getCustomerData.subscribe(data => this.formData = data);
    let formDataStorage = JSON.parse(sessionStorage.getItem('formData'));
    this.formData = formDataStorage

    if(this.formData && this.formData.rewards && this.formData.rewards.length && this.formData.rewards[0].isrewardCheckout == false){
      let rewardId = this.formData.rewards[0].rewardId, obj = {}
       this.apiService.getMarvalVouchers(mobile, token).subscribe(
        async (res: any) => {
          if (res['status'].code == 200) {
            this.voucherList = res?.userVouchers;
            this.voucherList = this.voucherList.filter(
              async(ress) => ress.rewardDetails != null
            );
            this.rewardDetails = this.voucherList.find(element => element.rewardDetails.rewardId == rewardId );
            this.rewardDetails['name']= this.rewardDetails['rewardDetails'].name
            if(this.rewardDetails['rewardDetails'].imageUrl){
              this.isImageDisplay = true
            }
            this.getTermsCon(this.rewardDetails['rewardDetails'].termNConditionsUrl)
            this.rewardDetails['image']= this.rewardDetails['rewardDetails'].imageUrl
            this.rewardDetails['termNConditionsUrl']= this.rewardDetails['rewardDetails'].termNConditionsUrl
            this.rewardDetails['code']= this.changeBarcode(this.rewardDetails.vouchers[this.rewardDetails.vouchers.length-1].code)
            this.rewardDetails['date']= this.rewardDetails.vouchers[this.rewardDetails.vouchers.length-1].issued
            this.rewardDetails['expiry']=  moment(this.rewardDetails.vouchers[this.rewardDetails.vouchers.length-1].expiry).format('DD/MM/YYYY')
            this.rewardDetails['isRedeemed']= this.rewardRedeemStatus(this.rewardDetails.vouchers[this.rewardDetails.vouchers.length-1].redemptionCount)
            this.rewardDetails['isActive']= this.rewardActiveStatus(this.rewardDetails.vouchers[this.rewardDetails.vouchers.length-1].redemptionCount,this.rewardDetails.vouchers[this.rewardDetails.vouchers.length-1].expiry)
            this.rewardDetails['isExpired']= this.rewardExpiryStatus(this.rewardDetails.vouchers[this.rewardDetails.vouchers.length-1].expiry)
            this.loader = true

            }
           
          })


    }else if(this.formData && this.formData.rewards && this.formData.rewards.length && this.formData.rewards[0].isrewardCheckout == true){
      this.apiService.getMarvalVouchers(mobile, token).subscribe(
        async (res: any) => {
          if (res['status'].code == 200) {
            this.voucherList = res?.userVouchers;
            this.voucherList = this.voucherList.filter(
              async(ress) => ress.rewardDetails != null
            );
            let rewardId = this.formData.rewards[0].rewardId,code=this.formData.rewards[0].code
            this.rewardDetails = this.voucherList.find(element => element.rewardDetails.rewardId == rewardId );
            this.rewardDetails['name']= this.rewardDetails['rewardDetails'].name
            if(this.rewardDetails['rewardDetails'].imageUrl){
              this.isImageDisplay = true
            }
            let voucher = this.rewardDetails.vouchers.find(element => element.code == code );
            
            this.rewardDetails['image']= this.rewardDetails['rewardDetails'].imageUrl
            this.rewardDetails['code']= this.changeBarcode(voucher.code)
            this.rewardDetails['date']= voucher.issued
            this.rewardDetails['expiry']=  moment(voucher.expiry).format('DD/MM/YYYY')
            this.rewardDetails['isRedeemed']= this.rewardRedeemStatus(voucher.redemptionCount)
            this.rewardDetails['isActive']= this.rewardActiveStatus(voucher.redemptionCount,voucher.expiry)
            this.rewardDetails['isExpired']= this.rewardExpiryStatus(voucher.expiry)
            this.loader=true

            }
           
          })
    }

    this.getTerms()

  }

  rewardExpiryStatus(date){
    var expiryDate = moment(date, 'YYYY-MM-DD');
    var currentDate = moment();
    return expiryDate.isBefore(currentDate) ? true:false
   
  }
  rewardRedeemStatus(count){
    return count != 0 ? true:false
   
  }
  rewardActiveStatus(count,date){
     var expiryDate = moment(date, 'YYYY-MM-DD');
     var currentDate = moment();
     return !expiryDate.isBefore(currentDate) && count == 0 ? true:false
   
}
onClickBack(){
  this.router.navigate(['/profile/transaction-history'])
}
getTermsCon(termsCon){
  if(termsCon){
   this.apiService.getRawData(termsCon)
    .subscribe((res) => {
     this.termsAndCondition = res
    })
  }
}
getTerms(){
  this.apiService.getTermsAndConditions().subscribe(
    (res:any) => {
      console.log("res =>", res)
      if (res['status'] == 200) {

        if(res?.data[0]?.tc){
          this.termAndCon = res?.data[0]?.tc
          this.safeHtmlContent = this.sanitizer.bypassSecurityTrustHtml(this.termAndCon);
        }
       
      } 
    },
    (err) => {
      console.log(err);
    }
  );

}
changeBarcode(barcode){
  if(barcode){
    this.apiService.changeBarcode(barcode);
  }
  
  
}


   

}